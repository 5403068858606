import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoriaCard from "./CategoriaCard";

import OwlCarousel from "react-owl-carousel2";
import { connect } from "react-redux";
import Slider from "react-slick";
export class CategoriaCarousel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 300,
      centerMode: true,
      variableWidth: true,
    };

    return (
      <div className="row">
        <div className="col-sm-12">
          <Slider {...settings}>
            {this.props.categorias_destacadas.map((categoria) => (
              <div>
                <div className="w-64 mx-2">
                  <CategoriaCard {...categoria}></CategoriaCard>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  categorias_destacadas: state.auth.categorias_destacadas,
}))(CategoriaCarousel);
