import axios from "axios";

import { API_URL } from "../../../constants";

export const loginApi = (token) => ({
  getData: async (file_data) => {
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      let response = await axios.get(`/datos/${file_data}.json`, null, options);
      console.log({ response });
      return response.data;
    } catch (error) {
      console.log({ error });
      throw error.response ? error.response.data : error;
    }
  },

  login: async (email, password) => {
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      email,
      password,
    };

    try {
      let response = await axios.post(`${API_URL}/auth/login`, body, options);
      console.log({ response });
      return { token: response.data.access_token };
    } catch (error) {
      console.log({ error });
      throw error.response ? error.response.data : error;
    }
  },

  registrar: async ({ usuario, direccion, facturacion }) => {
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      usuario,
      direccion,
      facturacion,
    };

    try {
      let response = await axios.post(
        `${API_URL}/auth/register`,
        body,
        options
      );
      console.log({ response });
      return { token: response.data.access_token };
    } catch (error) {
      console.log({ error });
      throw error.response ? error.response.data : error;
    }
  },

  me: async (token) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {};

    let response = await axios.post(`${API_URL}/auth/me`, body, options);
    console.log({ response });
    try {
      return response.data;
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  direcciones: async (token, direcciones) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      direcciones: JSON.stringify(direcciones),
    };

    let response = await axios.post(
      `${API_URL}/auth/direcciones`,
      body,
      options
    );

    try {
      return { token: response.data.token, user: response.data.user };
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  adicionales: async (token, adicionales) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      adicionales: JSON.stringify(adicionales),
    };

    let response = await axios.post(
      `${API_URL}/auth/adicionales`,
      body,
      options
    );

    try {
      return { token: response.data.token, user: response.data.user };
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },
});
