/* global google */
import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
} from "react-google-maps";

const iconUrls = {
  default: "https://maps.google.com/mapfiles/kml/paddle/grn-circle.png",
  selected: "https://maps.google.com/mapfiles/kml/paddle/red-circle.png",
};

class AreaMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      marker: {},
      center: {},
    };
    this.previosOverlay = null;
    // this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
  }

  renderRegions = () => {
    const { areas = [] } = this.props;

    if (areas.length == 0) return;

    return areas.map((area, idx) => {
      let { coordinates = [] } = area;
      let id = idx;

      let coorArr = [];
      coordinates.map((coord) =>
        coorArr.push({ lat: coord[1], lng: coord[0] })
      );

      return (
        <Polygon
          key={id}
          path={coorArr}
          onClick={this.onClickRegion(coorArr, id)}
          options={{ strokeColor: "#f6e05e" }}
        ></Polygon>
      );
    });
  };

  onChange = (area_id, latLng) => {
    console.log({ area_id, latLng });
  };

  onClickRegion = (coorArr = [], area_id = null) => (e) => {
    const { onChange = this.onChange } = this.props;

    this.setState({ marker: {} }, () => {
      onChange(null, this.state.marker);
    });

    if (coorArr.length < 1) {
      return;
    }

    const area = new google.maps.Polygon({
      paths: coorArr,
    });

    const enArea = google.maps.geometry.poly.containsLocation(e.latLng, area);

    if (!enArea) {
      return;
    }

    this.setState(
      {
        marker: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      },
      () => {
        onChange(area_id, this.state.marker);
      }
    );
  };

  renderMarker = () => {
    if (!this.state.marker.lat) return null;

    return <Marker position={this.state.marker} />;
  };

  render() {
    const { center = {}, point = {} } = this.props;
    const { marker = {} } = this.state;
    if (!!point.lat && !marker.lat) {
      this.setState({ marker: point });
    }

    return (
      <GoogleMap
        defaultZoom={this.props.zoom}
        defaultCenter={this.props.defaultCenter}
        {...(!!center.lat ? { center: center } : {})}
        zoom={16}
        options={{ clickableIcons: false }}
        onClick={this.onClickRegion()}
      >
        {this.renderRegions()}
        {this.renderMarker()}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(AreaMap));
