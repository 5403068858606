import React, { Component } from "react";
import { connect } from "react-redux";
import AreaMap from "../../../common/AreaMap";
import { SET_ADICIONALES, SET_DIRECCIONES } from "../handlers/constants";

class PerfilDatosPage extends Component {
  state = {
    sucursal: {},
    punto: {},
    direccion_formulario: false,
    direccion_editar: {},
    direcciones: [],
    adicionales_editar: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let { user = {} } = this.props;
    let { adicionales = {} } = user;

    this.setState({ adicionales_editar: adicionales || {} });

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Perfil",
        page_location: window.location.pathname,
      });
    }
  }

  onChangeAdicionales = (name) => (event) => {
    let { adicionales_editar = {} } = this.state;
    adicionales_editar[name] = event.target.value;
    this.setState({ adicionales_editar });
  };

  onSubmitAdicionales = (event) => {
    event.preventDefault();
    let { adicionales_editar = {} } = this.state;

    this.props.dispatch({
      type: SET_ADICIONALES,
      payload: { adicionales: adicionales_editar },
    });
  };

  render() {
    let { adicionales_editar = {} } = this.state;

    return (
      <>
        <div>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl">
              <form
                onSubmit={this.onSubmitAdicionales}
                className="row"
                style={{ justifyContent: "center" }}
              >
                <div className="w-full md:w-9/12">
                  <div className="bg-white shadow-sm rounded-md p-4 w-full">
                    <h4 className="text-2xl mb-3 text-yellow-500">Perfil</h4>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <label>Nombre</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.nombre || ""}
                          onChange={this.onChangeAdicionales("nombre")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Apellido</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.apellido || ""}
                          onChange={this.onChangeAdicionales("apellido")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Telefono</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.telefono_1 || ""}
                          onChange={this.onChangeAdicionales("telefono_1")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Otro telefono</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.telefono_2 || ""}
                          onChange={this.onChangeAdicionales("telefono_2")}
                        />
                      </div>
                      <div className="col form-group">
                        <label>RUC / CI (opcional)</label>
                        <input
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          value={adicionales_editar.ruc || ""}
                          onChange={this.onChangeAdicionales("ruc")}
                        />
                      </div>
                    </div>
                    <h4 className="text-2xl mb-3 text-yellow-500">
                      Cambiar contraseña
                    </h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value=""
                        />
                      </div>
                      <div className="col form-group">
                        <label>Confirmar nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value=""
                        />
                      </div>
                    </div>
                    <center>
                      <button className="btn btn-primary">Actualizar</button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            <div className="container 2xl:max-w-screen-xl padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  direcciones: state.auth.direcciones,
  user: state.auth.user,
  sucursales: state.auth.sucursales,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PerfilDatosPage);
