import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { AUTH_LOGOUT_REQUEST } from "../modules/Acceso/handlers/constants";
import { compose } from "redux";

import queryString from "query-string";

export class Header extends Component {
  state = {
    login: false,
    collapse: true,
    q: "",
  };

  constructor(props) {
    super();

    let { match = {}, location = {} } = props;
    const values = queryString.parse(location.search);

    this.state.q = values.q || "";
  }

  componentWillReceiveProps = (newProps) => {
    let { location = {}, history } = newProps;
    const values = queryString.parse(location.search);

    // this.setState({ q: values.q });

    window.scrollTo(0, 0);
  };

  onChangeBuscador = (event) => {
    let { location = {}, history } = this.props;

    let q = event.target.value || "";
    this.setState({ q: q });
  };

  onSubmitBuscador = (event) => {
    event.preventDefault();

    let { location = {}, history } = this.props;

    let q = this.state.q;
    history.push("/busqueda?q=" + q);
  };

  render() {
    let { match = {}, location = {} } = this.props;
    const values = queryString.parse(location.search);

    return (
      <div
        className={"border-b border-gray-200 shadow-sm relative z-20 py-2 "}
        style={{ background: "white" }}
      >
        <header className="section-header">
          <section className="">
            {/* menu mobile */}
            <div className="md:hidden w-full pt-2">
              <div className="container 2xl:max-w-screen-xl">
                <div className="navbar-light relative flex justify-between items-center">
                  <div>{this.renderMenuColapsable()}</div>
                  <div>{this.renderAccesoEnlaces()}</div>
                </div>
              </div>
            </div>
            {/* Menu normal */}
            <div className="container 2xl:max-w-screen-xl">
              <div className="flex flex-row flex-wrap items-center py-2">
                <div className="w-full md:w-auto md:h-auto h-20 md:mr-8 flex justify-center items-center">
                  <Link to="/" className="h-10 w-60 inline-block">
                    <img
                      className="w-full h-full object-contain"
                      src="/resource/logo-marca-yellow.png"
                    />
                  </Link>
                </div>
                <div className="w-full md:w-auto flex-grow justify-center md:justify-start flex">
                  {this.renderBuscador()}
                </div>
                <div className="hidden md:inline-block">
                  {this.renderAccesoEnlaces()}
                </div>
              </div>
            </div>
          </section>
        </header>

        <nav className="hidden md:flex items-center">
          <div className="container 2xl:max-w-screen-xl">
            <div
              className="row align-items-center no-margin"
              style={{ width: "100%" }}
            >
              <div className="col-lg-12 col-12 no-padding relative d-flex ">
                <div className="relative d-flex">
                  {this.renderMenuColapsable()}

                  <Link
                    className="inline-flex text-black items-center text-base px-3 py-2 hover:rounded"
                    to={`/menu`}
                  >
                    Menu del día
                  </Link>
                  <Link
                    className="inline-flex text-black items-center text-base px-3 py-2 hover:rounded"
                    to={`/locales`}
                  >
                    Locales
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }

  renderMenuColapsable = () => {
    const { collapse = true } = this.state;
    return (
      <>
        <a
          onClick={() => {
            this.setState({ collapse: !collapse });
          }}
          className="inline-flex text-black items-center text-base px-3 py-2 hover:rounded"
        >
          <span className="fa fa-bars text-xl font-bold"></span>
          <span className="font-normal ml-2">Categorias</span>
        </a>
        <div
          className={(collapse ? "collapse" : "") + " navbar-collapse absolute"}
          style={{
            top: "0px",
            left: "1px",
            marginTop: "40px",
            padding: "4px 0px",
            background: "white",
            zIndex: "10",
            border: "1px solid #e3e3e3",
            borderRadius: "6px",
          }}
          id="main_nav"
        >
          <div
            className="fixed top-0 bottom-0 left-0 right-0"
            onClick={() => {
              this.setState({ collapse: true });
            }}
          ></div>
          <ul
            className="navbar-nav relative z-10"
            style={{
              flexWrap: "wrap",
            }}
          >
            <li
              className="nav-item d-md-none"
              onClick={() => {
                this.setState({ collapse: true });
              }}
            >
              <Link className="nav-link  px-6" to={`/menu`}>
                Menu del día
              </Link>
            </li>
            {this.props.categorias_destacadas.map((categoria) => (
              <li
                key={categoria.id}
                className="nav-item"
                onClick={() => {
                  this.setState({ collapse: true });
                }}
              >
                <Link
                  className="block py-2 px-6"
                  style={{ textTransform: "capitalize" }}
                  to={`/categoria/${categoria.id}/${categoria.nombre}`}
                >
                  {categoria.nombre.toLocaleLowerCase()}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  renderCarritoContador = () => {
    return (
      <div className="relative inline-block ml-2">
        <Link to="/carrito">
          <i className="fa fa-shopping-cart text-3xl md:text-2xl text-yellow-500"></i>
        </Link>
        <span className=" flex justify-center items-center rounded-l-xl rounded-r-xl top-0 right-0 -mr-3 -mt-2  px-1 py-0 border-2 border-white font-bold text-white text-xs bg-red-500 absolute">
          {this.props.carrito_items.length}
        </span>
      </div>
    );
  };

  renderAccesoEnlaces = () => {
    return (
      <>
        {this.renderPerfil()}
        {this.renderSalir()}
        {this.renderCarritoContador()}
      </>
    );
  };

  renderPerfil = () => {
    return (
      <div className="relative inline-flex ml-3 items-center ">
        <Link to="/perfil">
          <i className="fa fa-user text-3xl md:text-2xl text-yellow-500"></i>
        </Link>
      </div>
    );
  };

  renderSalir = () => {
    if (!this.props.token) {
      return;
    }

    return (
      <div className="relative inline-flex ml-3 items-center ">
        <a
          className="text"
          style={{ cursor: "pointer" }}
          onClick={() =>
            this.props.dispath({
              type: AUTH_LOGOUT_REQUEST,
            })
          }
        >
          <i className="fa fa-sign-out-alt text-3xl md:text-2xl text-yellow-500"></i>
        </a>
      </div>
    );
  };

  renderBuscador = () => {
    return (
      <form
        action="#"
        className="search w-full md:w-64 lg:w-96  ease-linear duration-300 transform"
        onSubmit={this.onSubmitBuscador}
      >
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar un producto"
            value={this.state.q || ""}
            onChange={this.onChangeBuscador}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => ({
  categorias_destacadas: state.auth.categorias_destacadas,
  carrito_items: state.carrito.carrito_items,
  token: state.auth.token,
});

export default compose(
  connect(mapStateToProps, (dispath) => ({ dispath })),
  withRouter
)(Header);
