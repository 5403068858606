import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoriaHeader from "../../Categoria/components/CategoriaHeader";
import CategoriaFiltro from "../../Categoria/components/CategoriaFiltro";
import ProductoCard from "../../Producto/components/ProductoCard";
import queryString from "query-string";

import { connect } from "react-redux";

export class BusquedaPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Busqueda",
        page_location: window.location.pathname,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    let { match = {} } = this.props;
    let { params = {} } = match;

    let { newMatch = {} } = newProps;
    let { newParams = {} } = newMatch;

    if (params.categoria != newParams.categoria) {
      window.scrollTo(0, 0);
    }
  }

  getDatos = () => {
    let datos = this.props.productos;
    let { match = {}, location = {} } = this.props;
    let { params = {} } = match;

    const values = queryString.parse(location.search);

    let q = values.q.toLowerCase();

    datos = datos.filter((p) => {
      let { nombre = "" } = p;
      nombre = nombre.toLocaleLowerCase();

      return nombre.includes(q);
    });

    return datos;
  };

  render() {
    let { match = {} } = this.props;
    let { params = {} } = match;

    // let categoria = categorias.filter((c) => c.id == params.categoria).shift();

    return (
      <div>
        <CategoriaHeader nombre={"resultados"}></CategoriaHeader>
        <section className="section-content">
          <div className="container 2xl:max-w-screen-xl padding-y-sm">
            <div className="row">
              <aside className="col-md-3">
                <CategoriaFiltro categoria={{}}></CategoriaFiltro>
              </aside>
              <main className="col-md-9">
                {this.renderResultadoInfo()}
                {this.renderResultadoProductos()}
                {this.renderResultadoPaginacion()}
              </main>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderResultadoInfo = () => {
    let datos = this.props.productos;
    let { match = {} } = this.props;
    let { params = {} } = match;

    datos = this.getDatos();

    return (
      <header className=" pb-3">
        <div className="form-inline">
          <span className="mr-md-auto">
            {datos.length} Productos encontrados{" "}
          </span>
          <div className="btn-group">
            <Link
              to="#"
              className="btn btn-outline-secondary"
              data-toggle="tooltip"
              title=""
              data-original-title="List view"
            >
              <i className="fa fa-bars"></i>
            </Link>
            <Link
              to="#"
              className="btn  btn-outline-secondary active"
              data-toggle="tooltip"
              title=""
              data-original-title="Grid view"
            >
              <i className="fa fa-th"></i>
            </Link>
          </div>
        </div>
      </header>
    );
  };

  renderResultadoPaginacion = () => {
    return;
    // <nav className="mt-4" aria-label="Page navigation sample">
    //   <ul className="pagination">
    //     <li className="page-item disabled">
    //       <Link className="page-link" to="#">
    //         Anterior
    //       </Link>
    //     </li>
    //     <li className="page-item active">
    //       <Link className="page-link" to="#">
    //         1
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         2
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         3
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         Siguiente
    //       </Link>
    //     </li>
    //   </ul>
    // </nav>;
  };

  renderResultadoProductos = () => {
    let datos = this.props.productos;
    let { match = {} } = this.props;
    let { params = {} } = match;

    datos = this.getDatos();

    return (
      <div className="row">
        {datos.map((producto) => (
          <div className="col-md-4" key={producto.id}>
            <ProductoCard {...producto}></ProductoCard>
          </div>
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  categorias: state.auth.categorias,
  productos: state.auth.productos,
});

export default connect(mapStateToProps)(BusquedaPage);
