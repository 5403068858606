import React, { Component } from "react";
import { Link } from "react-router-dom";
import numeral from "numeral";

export class ProductoCard extends Component {
  render() {
    const { lista_fotos } = this.props;
    let imagen = lista_fotos[0] || {};
    let { foto } = imagen;
    return (
      <figure className="card card-product-grid">
        <div className="img-wrap">
          <Link to={`/producto/${this.props.codigo}`}>
            <img src={`${foto}`} />
          </Link>
        </div>
        <figcaption className="info-wrap">
          <Link
            to={`/producto/${this.props.codigo}`}
            className="title"
            style={{ textTransform: "capitalize" }}
          >
            {this.props.nombre.toLowerCase()}
          </Link>
          <div className="mt-2">
            <var className="price">
              Gs. {numeral(this.props.precio).format("0,0").replace(",", ".")}
            </var>
            <Link
              to={`/producto/${this.props.codigo}`}
              className="btn btn-sm btn-outline-primary float-right"
            >
              Agregar <i className="fa fa-shopping-cart"></i>
            </Link>
          </div>
        </figcaption>
      </figure>
    );
  }
}

export default ProductoCard;
