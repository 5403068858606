import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProductoGaleria extends Component {
  render() {
    const { lista_fotos } = this.props;
    let imagen = lista_fotos[0] || {};
    let { foto } = imagen;

    return (
      <aside className="card">
        <article className="gallery-wrap">
          <div className="img-big-wrap">
            <Link to="#">
              <img src={`${foto}`} />
            </Link>
          </div>
          {/* <div className="thumbs-wrap">
            <Link to="#" className="item-thumb">
              {" "}
              <img src="../images/items/1.jpg" />
            </Link>
            <Link to="#" className="item-thumb">
              {" "}
              <img src="../images/items/2.jpg" />
            </Link>
            <Link to="#" className="item-thumb">
              {" "}
              <img src="../images/items/3.jpg" />
            </Link>
            <Link to="#" className="item-thumb">
              {" "}
              <img src="../images/items/4.jpg" />
            </Link>
          </div> */}
        </article>
      </aside>
    );
  }
}

export default ProductoGaleria;
