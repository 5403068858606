import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AUTH_FAILURE, AUTH_REQUEST } from "../handlers/constants";

class IngresarCard extends React.Component {
  state = {
    email: "",
    password: "",
  };

  onChange = (name) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: AUTH_FAILURE, payload: "" });
    this.setState({ [name]: event.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.dispatch({
      type: AUTH_REQUEST,
      payload: {
        email,
        password,
      },
    });
  };

  render() {
    const { login_error = "" } = this.props;
    return (
      <div className="bg-white p-4 shadow-sm rounded">
        <h4 className="card-title mb-4 py-12 justify-center items-center flex">
          <img className="w-9/12" src="/resource/logo-marca-yellow.png"></img>
        </h4>
        <form onSubmit={this.submit} action="/perfil">
          <div className="form-group text-sm ">
            <label>Correo</label>
            <input
              name=""
              className="form-control"
              placeholder=""
              type="email"
              onChange={this.onChange("email")}
              value={this.state.email}
            />
          </div>
          <div className="form-group text-sm">
            {/* <a className="float-right" href="#">
                Recuperar
              </a> */}
            <label>Constraseña</label>
            <input
              className="form-control"
              placeholder=""
              type="password"
              onChange={this.onChange("password")}
              value={this.state.password}
            />
          </div>
          <div className="text-sm mb-4 italic">
            <Link className="text-gray-700 hover:underline" to="/registro">
              No tengo una cuenta, quiero registrarme.
            </Link>
          </div>
          <div className="form-group flex justify-center flex-col ">
            <button
              type="submit"
              className="inline-block btn btn-primary text-sm py-2 px-4"
            >
              Ingresar{" "}
            </button>
            <span
              style={{
                fontSize: "12px",
                color: "red",
                fontStyle: "italic",
              }}
            >
              {login_error}
            </span>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  login_error: state.auth.login_error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IngresarCard);
