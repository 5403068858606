import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  SET_DIRECCIONES,
  AUTH_SET_DATA,
} from "./constants";

import banner from "../../../datos/banner.json";
import categorias from "../../../datos/categorias.json";
import categorias_destacadas from "../../../datos/categorias_destacadas.json";
import productos from "../../../datos/productos.json";
import productos_destacadas from "../../../datos/productos_destacadas.json";
import sucursales from "../../../datos/sucursales.json";
import menu from "../../../datos/menu.json";

let token = localStorage.getItem("gp.token") || "";

const initialState = {
  token: token,
  user: "",
  direcciones: [],
  login_error: "",
  registro_error: {},
  banner,
  categorias,
  categorias_destacadas: [],
  productos,
  productos_destacadas,
  sucursales,
  sucursales_info: [],
  menu,
};

export const authReducer = (state = initialState, { type, payload, user }) => {
  switch (type) {
    case AUTH_SET_DATA: {
      return { ...state, ...payload };
    }

    case AUTH_SUCCESS: {
      return {
        ...state,
        token: payload,
        user,
        direcciones: user.direcciones || [],
      };
    }

    case AUTH_FAILURE: {
      return { ...state, login_error: payload };
    }
    case AUTH_LOGOUT: {
      localStorage.removeItem("gp.token");
      return { ...state, token: null, user: null, direcciones: [] };
    }

    case SET_DIRECCIONES: {
      return { ...state, direcciones: payload.direcciones };
    }

    case "REGISTRAR" + "_FAILURE": {
      return { ...state, registro_error: payload };
    }

    default:
      return state;
  }
};
