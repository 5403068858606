import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { Link, NavLink } from "react-router-dom";
import AreaMap from "../../../common/AreaMap";
import { SET_ADICIONALES, SET_DIRECCIONES } from "../handlers/constants";
import PerfilDatosPage from "./PerfilDatosPage";
import PerfilDireccionesPage from "./PerfilDireccionesPage";
import PerfilOrdenesPage from "./PerfilOrdenesPage";

class PerfilPage extends Component {
  state = {
    sucursal: {},
    punto: {},
    direccion_formulario: false,
    direccion_editar: {},
    direcciones: [],
    adicionales_editar: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let { user = {} } = this.props;
    let { adicionales = {} } = user;

    this.setState({ adicionales_editar: adicionales || {} });

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Perfil",
        page_location: window.location.pathname,
      });
    }
  }

  render() {
    return (
      <>
        <div>
          <section className="section-content bg-white">
            <div className="container 2xl:max-w-screen-xl py-3  bg-white">
              <h3 className="flex justify-center text-yellow-500 m-0">
                Bienvenido {this.props.user?.adicionales?.nombre}
              </h3>
            </div>
          </section>
          {this.renderMenu()}
          {this.renderSwitch()}
        </div>
      </>
    );
  }

  renderMenu = () => {
    let { match = {} } = this.props;
    let { url, path } = match;
    console.log({ url });
    return (
      <div className="bg-white shadow-sm mb-4">
        <div className="container 2xl:max-w-screen-xl">
          <div className="h-12 flex justify-center">
            <NavLink
              exact
              activeClassName="border-yellow-500"
              to={`${url}`}
              className="px-8 flex items-center border-b-2 border-transparent transition duration-500"
            >
              Perfil
            </NavLink>
            <NavLink
              exact
              activeClassName="border-yellow-500"
              to={`${url}/direcciones`}
              className="px-8 flex items-center border-b-2 border-transparent transition duration-500"
            >
              Dirección
            </NavLink>
            <NavLink
              exact
              activeClassName="border-yellow-500"
              to={`${url}/ordenes`}
              className="px-8 flex items-center border-b-2 border-transparent transition duration-500"
            >
              Ordenes
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  renderSwitch = () => {
    let { match = {} } = this.props;
    let { url, path } = match;

    return (
      <div className="container 2xl:max-w-screen-xl">
        <Switch>
          <Route exact path={path} component={PerfilDatosPage} />
          <Route
            path={`${path}/direcciones`}
            component={PerfilDireccionesPage}
          />
          <Route path={`${path}/ordenes`} component={PerfilOrdenesPage} />
        </Switch>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  direcciones: state.auth.direcciones,
  user: state.auth.user,
  sucursales: state.auth.sucursales,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PerfilPage);
