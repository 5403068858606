import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { authReducer } from "../app/modules/Acceso/handlers/reducer";
import { carritoReducer } from "../app/modules/Carrito/handlers/reducer";
const createRootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    router: connectRouter(history),
    carrito: carritoReducer,
  });

export default createRootReducer;
