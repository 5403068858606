import React, { Component } from "react";
import { connect } from "react-redux";
import AreaMap from "../../../common/AreaMap";
import { SET_ADICIONALES, SET_DIRECCIONES } from "../handlers/constants";

class PerfilDireccionesPage extends Component {
  state = {
    sucursal: {},
    punto: {},
    direccion_formulario: false,
    direccion_editar: {},
    direcciones: [],
    adicionales_editar: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let { user = {} } = this.props;
    let { adicionales = {} } = user;

    this.setState({ adicionales_editar: adicionales || {} });

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Perfil",
        page_location: window.location.pathname,
      });
    }
  }

  onChange = (id, point) => {
    let { direccion_editar = {} } = this.state;
    direccion_editar.sucursal = this.props.sucursales[id] || {};
    direccion_editar.punto = point || {};

    this.setState({ direccion_editar });
  };

  onChangeDireccionEditar = (name) => (event) => {
    let { direccion_editar = {} } = this.state;
    direccion_editar[name] = event.target.value;
    this.setState({ direccion_editar });
  };

  addDireccion = () => {
    let { direccion_editar = {} } = this.state;
    let { direcciones = [] } = this.props;

    let nuevo = false;
    if (!direccion_editar.id) {
      nuevo = true;
      direccion_editar.id = new Date().getTime();
    }

    if (!nuevo) {
      direcciones.map((direccion, idx) => {
        if (direccion.id == direccion_editar.id) {
          direcciones[idx] = direccion_editar;
        }
      });
    } else {
      direcciones.push(direccion_editar);
    }

    direccion_editar = {};
    this.setState(
      {
        direccion_editar,
        direccion_formulario: false,
      },
      () => {
        this.props.dispatch({
          type: SET_DIRECCIONES,
          payload: { direcciones },
        });
      }
    );
    console.log(JSON.stringify({ direcciones }));
  };

  editarDireccion = (direccion) => () => {
    let direccion_editar = direccion;
    this.setState({
      direccion_editar,
      direccion_formulario: true,
    });
  };

  render() {
    return (
      <div className="container 2xl:max-w-screen-xl">
        <div className="row justify-center">
          <div className="w-full md:w-9/12">
            {!this.state.direccion_formulario && (
              <div className="flex justify-between py-1 items-center mb-3">
                <h4>
                  <span>Direcciones de Envio</span>
                </h4>
                <a
                  className="py-2 px-4 bg-yellow-500
                  text-white font-semibold rounded-lg 
                  shadow-md hover:bg-yellow-700
                  text-sm 
                  focus:outline-none focus:bg-yellow-500 "
                  onClick={() =>
                    this.setState({
                      direccion_formulario: true,
                      direccion_editar: {},
                    })
                  }
                >
                  Agregar nueva
                </a>
              </div>
            )}
            <div className=" w-full">
              {!this.state.direccion_formulario && this.renderMisDirecciones()}
              {!!this.state.direccion_formulario &&
                this.renderAgregarDireccion()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMisDirecciones = () => {
    let { direcciones = [] } = this.props;

    return (
      <div className="">
        {direcciones.map((direccion) => (
          <div
            key={direccion.id}
            className="bg-white shadow-sm rounded-md p-4 mb-3 flex items-center justify-between"
          >
            <span>
              {direccion.ciudad} - {direccion.barrio}. {direccion.direccion}
            </span>
            <button
              class="py-1 px-3 border-2 border-yellow-500
                   text-yellow-500 font-semibold rounded-lg 
                   hover:text-yellow-600 hover:border-yellow-600 
                   shadow-md text-sm
                   focus:outline-none focus:border-yellow-500 
                   focus:ring-2 focus:ring-border-500 focus:ring-offset-2 
                   focus:ring-opacity-75"
              onClick={this.editarDireccion(direccion)}
            >
              Editar
            </button>
          </div>
        ))}
      </div>
    );
  };

  renderAgregarDireccion = () => {
    let { direccion_editar = {} } = this.state;
    let { sucursal = {} } = direccion_editar;
    return (
      <div className="bg-white shadow-sm rounded-md p-4 mb-3 ">
        <h4 className="text-2xl title mb-3 text-yellow-500">
          <span>Direcciones de Envio</span>
        </h4>
        <div className="form-row">
          <div className="col-12 form-group">
            <label>Dirección</label>
            <input
              type="text"
              value={direccion_editar.direccion || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("direccion")}
            />
          </div>
          <div className="col-6 form-group">
            <label>Barrio</label>
            <input
              type="text"
              value={direccion_editar.barrio || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("barrio")}
            />
          </div>
          <div className="col-6 form-group">
            <label>Ciudad</label>
            <input
              type="text"
              value={direccion_editar.ciudad || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("ciudad")}
            />
          </div>
          <div className="col-12 form-group">
            <label>Referencia</label>
            <input
              type="text"
              value={direccion_editar.referencia || ""}
              className="form-control"
              onChange={this.onChangeDireccionEditar("referencia")}
            />
          </div>
          <div className="col-12 form-group">
            <label>Sucursal asignada</label>
            <input
              type="readonly"
              value={sucursal.name || ""}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-12">
          <AreaMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGamRMBNFBB-x7Wrl-SSq-t4rqQiGRgBI"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultCenter={{ lat: -25.3011551, lng: -57.580372 }}
            zoom={4}
            areas={this.props.sucursales}
            point={direccion_editar.punto}
            onChange={this.onChange}
          />
        </div>
        <center style={{ marginTop: "10px" }}>
          <button className="btn btn-primary" onClick={this.addDireccion}>
            Guardar
          </button>
        </center>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  direcciones: state.auth.direcciones,
  user: state.auth.user,
  sucursales: state.auth.sucursales,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerfilDireccionesPage);
