import axios from "axios";

import { API_URL } from "../../../constants";

export const carritoApi = (token) => ({
  save: async (carrito) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      carrito: JSON.stringify(carrito),
    };

    let response = await axios.post(`${API_URL}/auth/carrito`, body, options);

    try {
      return { token: response.data.token, user: response.data.user };
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  pago: async ({
    carrito,
    envio,
    direccion,
    sucursal,
    razon_social = "",
    ruc_factura = "",
    comentario = "",
  }) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      carrito: JSON.stringify(carrito),
      envio,
      direccion: JSON.stringify(direccion),
      sucursal,
      razon_social,
      ruc_factura,
      comentario,
    };

    let response = await axios.post(
      `${API_URL}/auth/carrito_enlace`,
      body,
      options
    );

    try {
      return response.data;
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  pagoBancard: async ({
    carrito,
    envio,
    direccion,
    sucursal,
    razon_social = "",
    ruc_factura = "",
    comentario = "",
    celular = "",
    zimple = "",
  }) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      carrito: JSON.stringify(carrito),
      envio,
      direccion: JSON.stringify(direccion),
      sucursal,
      razon_social,
      ruc_factura,
      comentario,
      celular,
      zimple,
    };

    try {
      let response = await axios.post(
        `${API_URL}/auth/carrito_enlace_bancard`,
        body,
        options
      );

      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  ordenes: async ({}) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {};

    let response = await axios.post(`${API_URL}/auth/ordenes`, body, options);

    try {
      return response.data;
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },
});
