import React, { Component } from "react";
import { connect } from "react-redux";
import { setCarritoItems } from "../handlers/actions";
import { replace } from "connected-react-router";

class CarritoPagoPage extends Component {
  state = {
    envio: "",
    direccion_idx: null,
    sucursal_idx: null,
    factura_otro: false,
    razon_social: "",
    ruc_factura: "",
    comentario: "",
  };

  componentDidMount() {
    let { location = {} } = this.props;
    let { state = {} } = location;
    let { pago_info = {} } = state;

    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Carrito Pago",
        page_location: window.location.pathname,
      });
    }

    if (!pago_info.process_id) {
      this.props.dispatch(replace("/carrito"));
      return;
    }

    this.createBancardIframe(pago_info);
    console.log({ pago_info });
  }

  createBancardIframe = (pago_info) => {
    let styles = {
      "form-background-color": "#001b60",
      "button-background-color": "#4faed1",
      "button-text-color": "#fcfcfc",
      "button-border-color": "#dddddd",
      "input-background-color": "#fcfcfc",
      "input-text-color": "#111111",
      "input-placeholder-color": "#111111",
    };

    if (!!pago_info.zimple) {
      window.Bancard.Zimple.createForm(
        "iframe-container",
        pago_info.process_id,
        styles
      );
    } else {
      window.Bancard.Checkout.createForm(
        "iframe-container",
        pago_info.process_id,
        styles
      );
    }
  };

  render() {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm">
              <div className="row">
                <aside className="col-md-4 padding-bottom-sm">
                  {this.renderArticulos()}
                </aside>
                <aside className="col-md-8 padding-bottom-sm">
                  <div
                    style={{ height: "350px", margin: "auto" }}
                    id="iframe-container"
                  ></div>
                </aside>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }

  renderArticulos = () => {
    let { location = {} } = this.props;
    let { state = {} } = location;
    let { pago_info = {} } = state;

    return (
      <div className="card">
        <div class="card-body border-bottom">
          <h4
            className="card-title  no-gutters"
            style={{
              marginBottom: "0px",
            }}
          >
            Carrito de compras
          </h4>
        </div>
        <div className="row no-gutters">
          <aside className="col-md-12">
            <div className="card-body">
              {pago_info.descripcion.split("\n").map((v, k) => (
                <div key={k}>{v}</div>
              ))}
            </div>
          </aside>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  sucursales: state.auth.sucursales,
  carrito_items: state.carrito.carrito_items,
  direcciones: state.auth.direcciones,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setCarritoItems: (carrito_items) => {
    dispatch(setCarritoItems(carrito_items));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CarritoPagoPage);
