import React, { Component } from "react";
import { connect } from "react-redux";
import { setCarritoItems } from "../handlers/actions";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { CARRITO_PAGO } from "../handlers/constants";
import IngresarCard from "../../Acceso/components/IngresarCard";

class CarritoPage extends Component {
  state = {
    envio: "",
    direccion_idx: null,
    sucursal_idx: null,
    razon_social: "",
    ruc_factura: "",
    comentario: "",
    celular: "",
    zimple: false,
    factura_otro: false,
    agregar_comentario: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Carrito",
        page_location: window.location.pathname,
      });
    }
  }

  crearEnlacePago = () => {
    let {
      envio,
      zimple,
      direccion_idx,
      sucursal_idx,
      razon_social = "",
      ruc_factura = "",
      comentario = "",
      celular = "",
    } = this.state;
    let { carrito_items, direcciones } = this.props;

    this.props.dispatch({
      type: CARRITO_PAGO,
      payload: {
        carrito_items,
        envio: envio,
        razon_social,
        ruc_factura,
        comentario,
        zimple,
        celular,
        direccion: direccion_idx > -1 ? direcciones[direccion_idx] : null,
        sucursal: !!sucursal_idx ? sucursal_idx : null,
      },
    });
  };

  camposValidos = () => {
    if ((this.props.carrito_items || []).length < 1) return false;
    if (this.state.zimple == true && !this.state.celular) return false;
    return true;
  };

  render() {
    return (
      <>
        <div>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm">
              <div className="row">
                <aside className="col-md-7 padding-bottom-sm">
                  {this.renderDetalle()}
                </aside>
                {!!this.props.token && (
                  <aside className="col-md-5">{this.renderPago()}</aside>
                )}
                {!this.props.token && (
                  <aside className="col-md-5">
                    <IngresarCard></IngresarCard>
                  </aside>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }

  renderDetalle = () => {
    let montos = this.props.carrito_items.map(
      (item) => item.producto.precio * item.cantidad
    );
    let total = [...montos, 0, 0].reduce((p, c) => p + c);
    let envio = this.state.envio == "delivery" ? 15000 : 0;

    return (
      <>
        <div className="card">
          <div className="card-body border-bottom">
            <div
              className=" no-gutters"
              style={{
                marginBottom: "0px",
              }}
            >
              Carrito de compras
            </div>
          </div>
          <div className="row no-gutters">
            <aside className="col-md-12">
              {this.props.carrito_items.map((item, idx) => {
                const { lista_fotos } = item.producto;
                let imagen = lista_fotos[0] || {};
                let { foto } = imagen;

                return (
                  <article
                    key={idx + "_" + item.producto.id}
                    className="card-body border-bottom"
                  >
                    <div className="row">
                      <div className="col-md-7">
                        <figure className="media">
                          <div className="img-wrap mr-3">
                            <img
                              className="border img-sm w-80px"
                              src={`${foto}`}
                            />
                          </div>
                          <figcaption className="media-body">
                            <a href="#" className="title h6">
                              {item.producto.nombre}
                            </a>
                            <div className="price-wrap">
                              Gs.{" "}
                              {numeral(item.producto.precio * item.cantidad)
                                .format("0,0")
                                .replace(",", ".")}
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                      <div className="col-md-5 text-md-right text-right">
                        <div className="input-group input-spinner">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-light"
                              type="button"
                              id="button-plus"
                              onClick={() =>
                                this.updateCarritoItem(idx, item.cantidad + 1)
                              }
                            >
                              {" "}
                              +{" "}
                            </button>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            value={item.cantidad}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-light"
                              type="button"
                              id="button-minus"
                              onClick={() =>
                                this.updateCarritoItem(
                                  idx,
                                  item.cantidad > 1 ? item.cantidad - 1 : 1
                                )
                              }
                            >
                              {" "}
                              −{" "}
                            </button>
                          </div>
                        </div>
                        <a
                          href="#"
                          className="btn btn-light"
                          onClick={() => this.removeCarritoItem(idx)}
                        >
                          {" "}
                          <i className="fa fa-trash"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </article>
                );
              })}
              <article className="card-body">
                <dl className="row">
                  <dt className="col-sm-6">Subtotal: </dt>
                  <dd className="col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(total).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-sm-6">Costo de envio: </dt>
                  <dd className="col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(envio).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-sm-6">Total:</dt>
                  <dd className="col-sm-6 text-right">
                    <strong className="h5 text-dark">
                      Gs.{" "}
                      {numeral(total + envio)
                        .format("0,0")
                        .replace(",", ".")}
                    </strong>
                  </dd>
                </dl>
              </article>
            </aside>
          </div>
        </div>
      </>
    );
  };

  renderPago = () => {
    return (
      <>
        <div className="card mb-4">
          <div className="card-body">
            <div className="mb-3 font-bold text-md">Metodo de envio</div>
            <form>
              <div className="form-group">
                <label className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    checked=""
                    type="radio"
                    name="envio"
                    checked={this.state.envio === "retirar"}
                    onChange={(event) =>
                      this.setState({ envio: event.target.value })
                    }
                    value="retirar"
                  />
                  <span className="custom-control-label">
                    {" "}
                    Retirar de un local <br />
                    Gratis{" "}
                  </span>
                </label>
                <label className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    type="radio"
                    name="envio"
                    checked={this.state.envio === "delivery"}
                    onChange={(event) =>
                      this.setState({ envio: event.target.value })
                    }
                    value="delivery"
                  />
                  <span className="custom-control-label">
                    {" "}
                    Envio a domicilio <br /> Gs. 15.000{" "}
                  </span>
                </label>
              </div>
            </form>
          </div>
        </div>
        {!!this.state.envio && (
          <>
            {this.state.envio == "retirar" && this.renderRetirar()}
            {this.state.envio == "delivery" && this.renderDelivery()}
            {this.renderFacturacion()}
            {this.renderObservacion()}
            {this.renderZimple()}

            <div className="card mb-4">
              <div className="card-body">
                <div className="form-group">
                  <button
                    onClick={this.crearEnlacePago}
                    type="button"
                    className={"btn btn-primary btn-block disabled:bg-gray-500"}
                    disabled={!this.camposValidos()}
                  >
                    {" "}
                    Continuar{" "}
                  </button>
                </div>
                <div className=" mt-3 text-red-600">
                  {this.props.carrito_pago_error}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  renderFacturacion = () => {
    const { razon_social = "", ruc_factura = "", factura_otro } = this.state;
    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className=" flex flex-row justify-between  items-center">
            <label className={"mb-0"} htmlFor="factura_otro">
              <div className="font-bold text-md">Usar otra facturación</div>
            </label>
            <input
              type="checkbox"
              name="factura_otro"
              id="factura_otro"
              checked={this.state.factura_otro}
              onChange={(event) => {
                this.setState({ factura_otro: event.target.checked });
              }}
            />
          </div>
          {factura_otro && (
            <form>
              <div className="form-row mt-3">
                <div className="col form-group">
                  <label>Razón Social</label>
                  <input
                    type="text"
                    value={razon_social}
                    onChange={(event) =>
                      this.setState({ razon_social: event.target.value })
                    }
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col form-group">
                  <label>RUC</label>
                  <input
                    type="text"
                    value={ruc_factura}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = value.replace(/[^0-9-]/g, "");

                      this.setState({ ruc_factura: value });
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

  renderZimple = () => {
    const { celular = "", zimple = "" } = this.state;

    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className=" flex flex-row justify-between  items-center">
            <label className={"mb-0"} htmlFor="zimple">
              <div className="font-bold text-md">Pagar con Zimple</div>
            </label>
            <input
              type="checkbox"
              name="zimple"
              id="zimple"
              checked={this.state.zimple}
              onChange={(event) => {
                this.setState({ zimple: event.target.checked });
              }}
            />
          </div>
          {zimple && (
            <form>
              <div className="form-row mt-3">
                <div className="col form-group">
                  <label>Celular</label>
                  <input
                    type="text"
                    value={celular}
                    onChange={(event) =>
                      this.setState({ celular: event.target.value })
                    }
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

  renderObservacion = () => {
    const { comentario = "", agregar_comentario = "" } = this.state;
    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className=" flex flex-row justify-between  items-center">
            <label className={"mb-0"} htmlFor="agregar_comentario">
              <div className="font-bold text-md">Observación</div>
            </label>
            <input
              type="checkbox"
              name="agregar_comentario"
              id="agregar_comentario"
              checked={this.state.agregar_comentario}
              onChange={(event) => {
                this.setState({ agregar_comentario: event.target.checked });
              }}
            />
          </div>
          {agregar_comentario && (
            <form>
              <div className="form-row mt-3">
                <div className="form-group col-md-12">
                  <textarea
                    className={"border rounded"}
                    style={{ height: "80px", width: "100%" }}
                    value={comentario}
                    onChange={(event) =>
                      this.setState({ comentario: event.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  };

  renderDelivery = () => {
    const { direcciones = [] } = this.props;

    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className="mb-3 font-bold text-md">Datos de envio</div>
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Direcciones</label>
                {direcciones.length > 0 && (
                  <select
                    id="inputState"
                    className="form-control"
                    value={this.state.direccion_idx}
                    onChange={(event) =>
                      this.setState({ direccion_idx: event.target.value })
                    }
                  >
                    <option>Elegir...</option>
                    {direcciones.map((direccion, idx) => (
                      <option key={idx} value={idx}>
                        {direccion.ciudad} - {direccion.barrio}.{" "}
                        {direccion.direccion}
                      </option>
                    ))}
                  </select>
                )}
                <div style={{ paddingTop: "10px" }}>
                  <Link to="/perfil" className="btn btn-primary">
                    Nueva direccion
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderRetirar = () => {
    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className="mb-3 font-bold text-md">Pasar a buscar</div>
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Sucursal</label>
                <select
                  id="inputState"
                  className="form-control"
                  value={this.state.sucursal_idx}
                  onChange={(event) =>
                    this.setState({ sucursal_idx: event.target.value })
                  }
                >
                  <option>Elegir...</option>
                  {this.props.sucursales.map((s) => (
                    <option key={s} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  removeCarritoItem = (idx) => {
    let carrito_items = [...this.props.carrito_items];
    carrito_items.splice(idx, 1);
    this.props.setCarritoItems(carrito_items);
  };

  updateCarritoItem = (idx, cantidad) => {
    let carrito_items = [...this.props.carrito_items];

    carrito_items[idx]["cantidad"] = cantidad;

    this.props.setCarritoItems(carrito_items);
  };
}

const mapStateToProps = (state) => ({
  sucursales: state.auth.sucursales,
  carrito_items: state.carrito.carrito_items,
  carrito_pago_error: state.carrito.carrito_pago_error || "",
  direcciones: state.auth.direcciones,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setCarritoItems: (carrito_items) => {
    dispatch(setCarritoItems(carrito_items));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CarritoPage);
