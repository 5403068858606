import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_ORDENES } from "../../Carrito/handlers/constants";
import numeral from "numeral";

class PerfilOrdenesPage extends Component {
  state = {
    ordenes: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Orden",
        page_location: window.location.pathname,
      });
    }

    this.props.dispatch({ type: GET_ORDENES });
  }

  render() {
    return (
      <div className="container 2xl:max-w-screen-xl">
        <div className="row justify-center">
          <div className="w-full md:w-9/12">
            <div className="flex justify-between py-1 items-center mb-3">
              <h4>
                <span>Mis ordenes</span>
              </h4>
            </div>
            {this.renderMisOrdenes()}
          </div>
        </div>
      </div>
    );
  }

  renderMisOrdenes = () => {
    let { ordenes = [] } = this.props;

    return (
      <div className="" style={{ paddingTop: "0px" }}>
        {ordenes.map((orden) => (
          <OrdenItem orden={orden}></OrdenItem>
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  ordenes: state.carrito.ordenes,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PerfilOrdenesPage);

class OrdenItem extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { orden = {} } = this.props;

    let envio = orden.envio === "delivery" ? 15000 : 0;

    let montos = this.props.orden.carrito.map(
      (item) => item.producto.precio * item.cantidad
    );
    let total = [...montos, 0, 0].reduce((p, c) => p + c);

    let fecha = orden.created_at.split("T").shift();
    fecha = fecha.split("-");
    let day = fecha.pop();
    let month = fecha.pop();
    let year = fecha.pop();

    fecha = day + "/" + month + "/" + year;

    let direccion = orden.direccion || {};
    direccion = direccion?.direccion;

    return (
      <div key={orden.id} className="bg-white shadow-sm rounded-md mb-3">
        <a
          className="py-4 block text-gray-800"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className="flex flex-row items-center px-4">
            <div className="text-base capitalize">{fecha}</div>
            <div className="text-base capitalize font-bold ml-4">
              {orden.envio}
            </div>
            <div className="text-base capitalize font-bold ml-4">
              {direccion}
            </div>
            <div className="text-base flex-grow text-right font-bold  ml-4">
              Gs.{" "}
              {numeral(total + envio)
                .format("0,0")
                .replace(",", ".")}
            </div>
            <i
              className={
                "fa" +
                (this.state.open ? " fa-angle-down " : " fa-angle-up ") +
                " w-5 text-right"
              }
            ></i>
          </div>
        </a>
        {this.state.open && (
          <aside className="w-full pb-3">
            {orden.carrito.map(this.renderOrden)}
          </aside>
        )}
      </div>
    );
  }
  renderOrden = (item, idx) => {
    const { lista_fotos } = item.producto;
    let imagen = lista_fotos[0] || {};
    let { foto } = imagen;
    let precio_total = numeral(item.producto.precio)
      .format("0,0")
      .replace(",", ".");

    return (
      <article
        key={idx + "_" + item.producto.id}
        className="px-4 pt-3 border-t border-gray-100"
      >
        <figure className=" flex flex-row items-center w-full">
          <div className="w-16 h-16 mr-3">
            <img className="w-full h-full" src={`${foto}`} />
          </div>
          <figcaption className="text-base capitalize flex-grow">
            {item.producto?.nombre?.toLowerCase()}
          </figcaption>
          <div className="price-wrap text-base mr-4">
            {item.cantidad} x Gs. {precio_total}
          </div>
        </figure>
      </article>
    );
  };
}
