import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { connect } from "react-redux";

export class Banner extends Component {
  state = {
    nav1: null,
    nav2: null,
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">{this.renderHorizontal()}</div>
        {/* {this.renderVertical()} */}
      </div>
    );
  }

  renderHorizontal = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <Slider {...settings} ref={(slider) => (this.slider1 = slider)}>
        {(this.props.banners || []).map((banner) => {
          let imagen = banner.imagen_path;
          if (!!imagen) {
            imagen = `${imagen}`;
          }
          return (
            <div>
              <a
                className="card-banner primary flex flex-col"
                style={{
                  backgroundImage: `url(${imagen}) `,
                  // backgroundImage: `url(https://godspan.com.py/images/banner_imagenes/1613046250_para-la-web.jpg) `,
                }}
                href={banner.enlace}
              >
                <div class="h-24 md:h-36 lg:h-56 xl:h-64"></div>
                <div class="h-28 md:h-36 lg:h-56 xl:h-64"></div>
                {(banner.titulo || "").trim().length > 0 && (
                  <article className="caption bottom">
                    <h5 className="card-title">{banner.titulo}</h5>
                    <p>{banner.descripcion}</p>
                  </article>
                )}
              </a>
            </div>
          );
        })}
      </Slider>
    );
  };

  renderVertical = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      centerPadding: "0",
    };

    let banner_nuevo = this.props.banners;
    let banner = banner_nuevo.shift();
    banner_nuevo.push(banner);

    return (
      <div
        className="col-md-3 d-none d-md-block"
        style={{
          maxHeight: " 351px",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <Slider
          {...settings}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
        >
          {banner_nuevo.map((banner) => {
            let imagen = banner.imagen_path;
            if (!!imagen) {
              imagen = `${imagen}`;
            }
            return (
              <div>
                <div
                  className="card-banner secondary"
                  style={{
                    backgroundImage: `url(${imagen}) `,
                  }}
                ></div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };
}

export default connect((state) => ({ banners: state.auth.banner }))(Banner);
