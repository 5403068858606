import React, { Component } from "react";
import { Link } from "react-router-dom";

export class CategoriaCard extends Component {
  render() {
    const { foto } = this.props;
    return (
      <Link
        className="category-card card-banner"
        to={`/categoria/${this.props.id}/${this.props.nombre}`}
        style={{ marginBottom: "20px" }}
      >
        <div
          className="card-body"
          style={{
            backgroundImage: "url(" + foto + ")",
            backgroundSize: "cover",
            backgroundPosition: "50%",
          }}
        ></div>

        <article className="caption bottom">
          <h5 className="card-title" style={{ textTransform: "capitalize" }}>
            {this.props.nombre.toLowerCase()}
          </h5>
        </article>
      </Link>
    );
  }
}

export default CategoriaCard;
