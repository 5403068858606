import React, { Component } from "react";
import CategoriaHeader from "../../Categoria/components/CategoriaHeader";
import queryString from "query-string";
import { Redirect } from "react-router";

class CarritoPagadoPage extends Component {
  renderSuccess = () => {
    return (
      <>
        <div className={"text-xl font-bold text-green-600"}>
          Estamos procesando su compra
        </div>
        <div className={"text-base"}>Muchas gracias por elegirnos</div>
      </>
    );
  };

  renderError = () => {
    return (
      <>
        <div className={"text-xl font-bold text-red-500"}>
          Hubo un error al realizar el pago
        </div>
        <div className={"text-base"}>Vuelva a intentar mas tarde</div>
      </>
    );
  };
  render() {
    let { match = {}, location = {} } = this.props;
    const values = queryString.parse(location.search) || {};
    if (!values.status) {
      return <Redirect to="/"></Redirect>;
    }
    let status = values.status.toLowerCase();

    return (
      <div>
        <div>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm">
              <div className="row rounded bg-white shadow">
                <div
                  className={
                    "flex flex-col justify-center items-center h-80 w-full"
                  }
                >
                  <div>
                    <img
                      className={"w-60 mb-12"}
                      src="/resource/logo-marca-yellow.png"
                    ></img>
                  </div>
                  {status == "payment_success"
                    ? this.renderSuccess()
                    : this.renderError()}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CarritoPagadoPage;
