import React, { Component } from "react";
import Banner from "../components/Banner";
import CategoriaCarousel from "../../Categoria/components/CategoriaCarousel";
import ProductoCarousel from "../../Producto/components/ProductoCarousel";
import Informaciones from "../components/Informaciones";
import { Link } from "react-router-dom";

export class Main extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Home",
        page_location: window.location.pathname,
      });
    }
  }

  render() {
    return (
      <div>
        {this.renderMenuEnlace()}
        {this.renderBanner()}
        {this.renderCategorias()}
        {this.renderProductos()}
        {this.renderInformaciones()}
      </div>
    );
  }

  renderMenuEnlace = () => {
    return (
      <section className="categoria-header section-content bg d-block d-md-none">
        <div className="container 2xl:max-w-screen-xl">
          <Link
            to="/menu"
            className="section-heading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              height: "80px",
            }}
          >
            <i>
              <h3
                className="section-title"
                style={{ margin: 0, textTransform: "inherit" }}
              >
                <span
                  style={{
                    margin: 0,
                    textTransform: "initial",
                  }}
                >
                  Ver menú del día
                </span>
              </h3>
            </i>
          </Link>
        </div>
      </section>
    );
  };

  renderBanner = () => {
    return (
      <section className="section-intro">
        <div className="container 2xl:max-w-screen-xl padding-y-sm">
          <Banner></Banner>
        </div>
      </section>
    );
  };

  renderCategorias = () => {
    return (
      <section className="section-intro">
        <div className="container 2xl:max-w-screen-xl padding-y-sm">
          <header className="section-heading">
            <h3 className="text-2xl text-yellow-500 font-bold">
              Compra por categorias
            </h3>
          </header>
          <CategoriaCarousel></CategoriaCarousel>
        </div>
      </section>
    );
  };

  renderProductos = () => {
    return (
      <section className="section-intro">
        <div className="container 2xl:max-w-screen-xl padding-y-sm">
          <header className="section-heading">
            <h3 className="text-2xl text-yellow-500 font-bold">
              Productos destacados
            </h3>
          </header>
          <ProductoCarousel></ProductoCarousel>
        </div>
      </section>
    );
  };

  renderInformaciones = () => {
    return (
      <section className="section-intro">
        <div
          className="container 2xl:max-w-screen-xl padding-y-sm"
          style={{ paddingBottom: "12px" }}
        >
          <Informaciones></Informaciones>
        </div>
      </section>
    );
  };
}

export default Main;
