import React from "react";
import { useSelector } from "react-redux";
import CategoriaHeader from "../../Categoria/components/CategoriaHeader";

const LocalesPage = () => {
  const sucursales_info = useSelector((state) => state.auth.sucursales_info);

  return (
    <div>
      <div>
        <CategoriaHeader nombre={<span>locales</span>}></CategoriaHeader>
        <section className="section-content">
          <div className="container 2xl:max-w-screen-xl padding-y-sm">
            <div className="row">
              {sucursales_info.map((local) => (
                <div
                  key={local.nombre}
                  className="col-12 col-md-6"
                  style={{ marginBottom: "24px" }}
                >
                  <Local local={local}></Local>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export const Local = ({ local = {} }) => {
  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body">
        <div>
          <h5 style={{ color: "#fdbf00", marginBottom: 0 }}>{local.nombre}</h5>
        </div>
        {!!local.direccion && (
          <div style={{ marginBottom: "8px" }}>
            <i>{local.direccion}</i>
          </div>
        )}
        {!!local.horario && (
          <div style={{ marginBottom: "0px" }}>{local.horario}</div>
        )}
        {!!local.disponible && (
          <div style={{ marginBottom: "8px" }}>{local.disponible}</div>
        )}
        {!!local?.telefono?.length && (
          <div style={{ marginBottom: "0px" }}>
            Telefonos:{" "}
            {local.telefono.map((t, idx) => (
              <span key={idx}>
                <a href={"tel:" + t}>{t}</a>
                {idx < local.telefono.length - 1 && " - "}
              </span>
            ))}
          </div>
        )}
        {!!local.whatsapp && (
          <div style={{ marginBottom: "0px" }}>
            WhatsApp:{" "}
            <a target="_blank" href={"https://wa.me/" + local.whatsapp}>
              +{local.whatsapp}
            </a>
          </div>
        )}
        {!!local.correo && (
          <div style={{ marginBottom: "4px" }}>
            Email: <a href={"mailto:" + local.correo}>{local.correo}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocalesPage;
