import React, { Component } from "react";

export class ProductoDescripcion extends Component {
  render() {
    const { descripcion = "" } = this.props;

    return (
      <article className="card">
        <div className="card-body">
          <div className="row">
            <aside className="col-md-12" style={{ paddingBottom: "10px" }}>
              <h5>Descripción</h5>
            </aside>
            <aside className="col-md-12" style={{ paddingBottom: "10px" }}>
              {descripcion.split("\n").map((texto) => (
                <>
                  {texto}
                  <br />
                </>
              ))}
            </aside>
          </div>
        </div>
      </article>
    );
  }
}

export default ProductoDescripcion;
