import React, { Component } from "react";

export class ProductoInformacionNutricional extends Component {
  render() {
    return (
      <article className="producto-informacion-nutricional card d-none d-lg-block">
        <div className="card-body">
          <div className="row">
            <aside className="col-md-12 header-info">
              <h5>Información nutricional</h5>
              <h6>Porción de 30g (11 unidades)</h6>
            </aside>
            <aside className="col-md-12">
              <dl className="row">
                <dt className="col-sm-4"></dt>
                <dt className="col-sm-4">Cant. por porción</dt>
                <dt className="col-sm-4 text-center">% VD (*)</dt>

                <dd className="col-sm-4">Valor energético</dd>
                <dd className="col-sm-4 text-center">147 Kcal = 614 KJ</dd>
                <dd className="col-sm-4 text-center">7</dd>

                <dd className="col-sm-4">Carbohidratos</dd>
                <dd className="col-sm-4 text-center">24,4 g</dd>
                <dd className="col-sm-4 text-center">8</dd>

                <dd className="col-sm-4">Proteínas</dd>
                <dd className="col-sm-4 text-center">3,4 g</dd>
                <dd className="col-sm-4 text-center">5</dd>

                <dd className="col-sm-4">Grasas Totales</dd>
                <dd className="col-sm-4 text-center">4 g</dd>
                <dd className="col-sm-4 text-center">7</dd>

                <dd className="col-sm-4">Grasas Saturadas</dd>
                <dd className="col-sm-4 text-center">1,3 g</dd>
                <dd className="col-sm-4 text-center">6</dd>

                <dd className="col-sm-4">Grasas Trans</dd>
                <dd className="col-sm-4 text-center">0 g</dd>
                <dd className="col-sm-4 text-center">**</dd>

                <dd className="col-sm-4">Fibra alimentaria</dd>
                <dd className="col-sm-4 text-center">1 g</dd>
                <dd className="col-sm-4 text-center">4</dd>

                <dd className="col-sm-4">Sodio</dd>
                <dd className="col-sm-4 text-center">165 mg</dd>
                <dd className="col-sm-4 text-center">7</dd>
              </dl>
            </aside>
          </div>
          <hr />
          <p>
            (*) % Valores diarios con base a una dieta de 2.000 kcal u 8.400 Kj.
            Sus valores diarios pueden ser mayores o menores dependiendo de sus
            necesidades energéticas
          </p>
        </div>
      </article>
    );
  }
}

export default ProductoInformacionNutricional;
