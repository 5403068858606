import React, { Component } from "react";
import { connect } from "react-redux";
import AreaMap from "../../../common/AreaMap";
import { SET_DIRECCIONES } from "../handlers/constants";

class RegistroPage extends Component {
  state = {
    usuario: {},
    facturacion: {},
    sucursal: {},
    punto: {},
    direccion: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Registro",
        page_location: window.location.pathname,
      });
    }
  }

  register = (event) => {
    event.preventDefault();
    let { usuario = {}, direccion = {}, facturacion = {} } = this.state;

    this.props.dispatch({
      type: "REGISTRAR",
      payload: {
        usuario,
        direccion,
        facturacion,
      },
    });
  };

  render() {
    const { usuario = {}, facturacion = {}, direccion = {} } = this.state;
    const { sucursal = {} } = direccion;
    const { registro_error = {} } = this.props;
    return (
      <>
        <div>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm">
              <form
                onSubmit={this.register}
                className="row"
                style={{ justifyContent: "center" }}
              >
                <div className="card col-md-9">
                  <div className="card-body">
                    <h4 className="card-title mb-3">Registro</h4>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <label>Nombre</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.nombre || ""}
                          onChange={this.onChangeUsuario("nombre")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.nombre}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Apellido</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.apellido || ""}
                          onChange={this.onChangeUsuario("apellido")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.apellido}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          value={usuario.email || ""}
                          onChange={this.onChangeUsuario("email")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.email}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Telefono</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.telefono_1 || ""}
                          onChange={this.onChangeUsuario("telefono_1")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.telefono_1}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Otro telefono (opcional)</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.telefono_2 || ""}
                          onChange={this.onChangeUsuario("telefono_2")}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.telefono_2}
                        </span>
                      </div>
                      <div className="col form-group">
                        <label>RUC (opcional)</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.ruc || ""}
                          onChange={this.onChangeUsuario("ruc")}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.ruc}
                        </span>
                      </div>
                    </div>
                    <h4 className="card-title mb-3">Contraseña</h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value={usuario.clave || ""}
                          onChange={this.onChangeUsuario("clave")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.clave}
                        </span>
                      </div>
                      <div className="col form-group">
                        <label>Confirmar nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value={usuario.clave_confirmar || ""}
                          onChange={this.onChangeUsuario("clave_confirmar")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.clave}
                        </span>
                      </div>
                    </div>

                    <h4 className="card-title mb-3">Dirección</h4>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <label>Dirección</label>
                        <input
                          type="text"
                          value={direccion.direccion || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("direccion")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.direccion}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Barrio</label>
                        <input
                          type="text"
                          value={direccion.barrio || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("barrio")}
                          required
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.barrio}
                        </span>
                      </div>
                      <div className="col-6 form-group">
                        <label>Ciudad</label>
                        <select
                          value={direccion.ciudad || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("ciudad")}
                          required
                        >
                          <option value=""></option>
                          <option>Asunción</option>
                          <option>Fernando de la Mora</option>
                          <option>San Lorenzo</option>
                          <option>Mariano</option>
                        </select>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.ciudad}
                        </span>
                      </div>
                      <div className="col-12 form-group">
                        <label>Referencia (opcional)</label>
                        <input
                          type="text"
                          value={direccion.referencia || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("referencia")}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {registro_error.referencia}
                        </span>
                      </div>
                      <div className="col-12 form-group">
                        <label>Sucursal asignada</label>
                        <div style={{ fontWeight: "semibold" }}>
                          {sucursal.name || "(Haga click en el map)"}&nbsp;
                        </div>
                      </div>

                      <div className="col-12 form-group">
                        <AreaMap
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGamRMBNFBB-x7Wrl-SSq-t4rqQiGRgBI"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          defaultCenter={{ lat: -25.3011551, lng: -57.580372 }}
                          zoom={4}
                          areas={this.props.sucursales}
                          point={direccion.punto}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <center>
                      <button className="btn btn-primary">Registrarme</button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            <div className="container 2xl:max-w-screen-xl padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }

  onChange = (id, point) => {
    let { direccion = {} } = this.state;
    direccion.sucursal = this.props.sucursales[id] || {};
    direccion.punto = point || {};

    console.log({ id, direccion });
    this.setState({ direccion });
  };

  onChangeUsuario = (name) => (event) => {
    let { usuario = {} } = this.state;
    let { registro_error = {}, dispatch } = this.props;

    usuario[name] = event.target.value;
    registro_error[name] = "";

    dispatch({ type: "REGISTRAR" + "_FAILURE", payload: registro_error });

    this.setState({ usuario });
  };

  onChangeDireccionEditar = (name) => (event) => {
    let { direccion = {} } = this.state;
    let { registro_error = {}, dispatch } = this.props;

    registro_error[name] = "";
    dispatch({ type: "REGISTRAR" + "_FAILURE", payload: registro_error });

    direccion[name] = event.target.value;
    this.setState({ direccion });
  };
}

const mapStateToProps = (state) => ({
  sucursales: state.auth.sucursales,
  token: state.auth.token,
  registro_error: state.auth.registro_error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistroPage);
