import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import CategoriaHeader from "../../Categoria/components/CategoriaHeader";

export class MenuPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Menú Día",
        page_location: window.location.pathname,
      });
    }
  }

  render() {
    let { menu = [] } = this.props;
    return (
      <div>
        <div>
          <CategoriaHeader
            nombre={
              <span>Menu del día{!!menu.fecha ? " - " + menu.fecha : ""}</span>
            }
          ></CategoriaHeader>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm">
              <div className="row">
                {(menu.listas || []).map((lista) => (
                  <div className="col-12 col-md-6">
                    <h5>{lista.name}</h5>
                    <div className="card" style={{ marginBottom: "16px" }}>
                      <div className="card-body">
                        <ul style={{ listStyle: "none", paddingLeft: "8px" }}>
                          {(lista.items || []).map((item) => (
                            <li className="list-item" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.auth.menu,
});

export default compose(
  connect(mapStateToProps, (dispath) => ({ dispath })),
  withRouter
)(MenuPage);
