import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import PrivateRoute from "../router/PrivateRoute";
import Header from "./common/Header";
import Main from "./modules/Inicio/pages/Main";
import LoginPage from "./modules/Acceso/pages/LoginPage";
import About from "./About";
import Contact from "./modules/Contacto/pages/Contact";
import PerfilPage from "./modules/Acceso/pages/PerfilPage";
import Footer from "./common/Footer";
import CategoriaPage from "./modules/Categoria/pages/CategoriaPage";
import ProductoPage from "./modules/Producto/pages/ProductoPage";
import CarritoPage from "./modules/Carrito/pages/CarritoPage.js";
import RegistroPage from "./modules/Acceso/pages/RegistroPage";
import BusquedaPage from "./modules/Busqueda/pages/BusquedaPage";
import MenuPage from "./modules/Menu/pages/MenuPage";
import TerminosPage from "./modules/Contacto/pages/TerminosPage";
import EventosPage from "./modules/Contacto/pages/EventosPage";
import LocalesPage from "./modules/Contacto/pages/LocalesPage";
import CarritoPagoPage from "./modules/Carrito/pages/CarritoPagoPage";
import CarritoPagadoPage from "./modules/Carrito/pages/CarritoPagadoPage";

export const Router = (props) => {
  const { history } = props;
  return (
    <ConnectedRouter history={history}>
      <div>
        <Header />
        <div className="contenido pb-4" style={{ minHeight: "70vh" }}>
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/categoria/:categoria" component={CategoriaPage} />
            <Route path="/producto/:producto" component={ProductoPage} />
            <Route path="/ingresar" component={LoginPage} />
            <Route path="/registro" component={RegistroPage} />
            <Route exact path="/carrito" component={CarritoPage} />
            <Route exact path="/carrito/pago" component={CarritoPagoPage} />
            <Route path="/compra_finalizada" component={CarritoPagadoPage} />
            <Route path="/busqueda" component={BusquedaPage} />
            <Route path="/menu" component={MenuPage} />
            <Route exact path="/menu.html">
              <Redirect to="/menu" />
            </Route>
            {/* <Route path="/contact" component={Contact} /> */}
            <Route path="/terminos" component={TerminosPage} />
            <Route path="/locales" component={LocalesPage} />
            <Route path="/eventos" component={EventosPage} />
            <PrivateRoute path="/perfil" component={PerfilPage} />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </ConnectedRouter>
  );
};
