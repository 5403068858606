import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_LOGOUT } from "./modules/Acceso/handlers/constants";
// import { hot } from "react-hot-loader/root";

import { Router } from "./router";

const App = (props) => {
  // Place this line in your function
  const { history } = props;

  const dispatch = useDispatch();
  const [active, setActive] = React.useState(document.activeElement);

  const handleFocusIn = (e) => {
    console.log("===>", "evento:", true);
    setActive(true);
  };
  const handleFocusOut = (e) => {
    console.log("===>", "evento:", false);
    setActive(false);
  };

  React.useEffect(() => {
    window.addEventListener("blur", handleFocusOut);
    window.addEventListener("focus", handleFocusIn);

    return () => {
      window.removeEventListener("blur", handleFocusOut);
      window.removeEventListener("focus", handleFocusIn);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("gp.token");
    if (!!token) {
      dispatch({
        type: "VALIDAR_TOKEN",
      });
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("gp.token");
    console.log("===>", "estado:", JSON.stringify(active));
    if (!active) return;

    if (!!token) {
      dispatch({
        type: "VALIDAR_TOKEN",
      });
    } else {
      dispatch({
        type: AUTH_LOGOUT,
      });
    }
  }, [active]);

  return <Router history={history} />;
};

export default App;
