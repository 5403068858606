import React, { Component } from "react";
import { connect } from "react-redux";
import IngresarCard from "../components/IngresarCard";
import { AUTH_FAILURE, AUTH_REQUEST } from "../handlers/constants";

class Login extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (!!window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "Gods Pan - Home",
        page_location: window.location.pathname,
      });
    }
  }

  render() {
    const { login_error = "" } = this.props;
    return (
      <>
        <div>
          <section className="section-content">
            <div
              className="container 2xl:max-w-screen-xl padding-y-sm"
              style={{ marginBottom: "40vh" }}
            >
              <div className="row" style={{ justifyContent: "center" }}>
                <aside className="w-8/12 md:w-5/12 lg:w-4/12">
                  <IngresarCard></IngresarCard>
                </aside>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section className="section-content">
            <div className="container 2xl:max-w-screen-xl padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  login_error: state.auth.login_error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
