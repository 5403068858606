import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ProductoCard } from "./ProductoCard";
import OwlCarousel from "react-owl-carousel2";
import { connect } from "react-redux";
import Slider from "react-slick";

export class ProductoCarousel extends Component {
  // options = {
  //   items: 1.5,
  //   nav: false,
  //   loop: true,
  //   margin: 20,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1.5,
  //       nav: false,
  //       loop: true,
  //     },
  //     776: {
  //       items: 2.5,
  //       nav: false,
  //       loop: true,
  //     },
  //     1000: {
  //       items: 4,
  //       nav: false,
  //       loop: true,
  //     },
  //   },
  // };

  // events = {
  //   onDragged: function (event) {},
  //   onChanged: function (event) {},
  //   onInitialized: function (event) {
  //     let owl = window.$(event.target);

  //     owl.on("mousewheel", ".owl-stage", function (e) {
  //       if (e.deltaY > 0) {
  //         owl.trigger("next.owl");
  //       } else {
  //         owl.trigger("prev.owl");
  //       }
  //       e.preventDefault();
  //     });
  //   },
  // };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 300,
      centerMode: true,
      variableWidth: true,
    };

    return (
      <div className="row">
        <div className="col-sm-12">
          <Slider {...settings}>
            {this.props.productos_destacadas.map((producto) => {
              producto =
                this.props.productos
                  .filter((p) => p.id == producto.id)
                  .shift() || {};

              if (!producto.codigo) {
                return;
              }

              return (
                <div>
                  <div className="w-64 mx-2">
                    <ProductoCard {...producto}></ProductoCard>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productos_destacadas: state.auth.productos_destacadas,
  productos: state.auth.productos,
});

export default connect(mapStateToProps)(ProductoCarousel);
